import { memo, useState, useEffect } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import Heading from "../typography/Heading";
import ButtonLogout from "../button/Button.logout";
import Paragraph from "../typography/Paragraph";
import { userMenu } from "./listMenu/new-user";
import { HiChevronLeft, HiOutlineMenu } from "react-icons/hi";
import useAuth from "../../../hooks/zustand/useAuth";
import usePersonalInformation from "../../../hooks/zustand/usePersonalInformation";
import { Outlet, useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";
import { Link } from "react-router-dom";
import Spacing from "../spacing/Spacing";
import { Suspense } from "react";
import Loading from "../Loading";
import { convertTime } from "../../../lib/table-date.formatter";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { mockLanguages } from "../../../mock/mockData";
import useHomeSlider from "../../../hooks/zustand/useHomeSlider";
const themes = {
  dark: {
    sidebar: {
      backgroundColor: "#202123",
      color: "white",
    },
    menu: {
      menuContent: "#202123",
      icon: "#fffff",
      hover: {
        backgroundColor: "#343541",
        color: "#fffff",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

// hex to rgba converter
const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const DashboardLayout = memo(({ children }) => {
  const userInfo = usePersonalInformation(
    (state) => ({ user: state.user, reset: state.reset }),
    shallow
  );
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const homeSliderStore = useHomeSlider();
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [hasImage] = useState(false);
  const [theme] = useState("dark");
  const [bgImg, setBgImg] = useState(process.env.REACT_APP_LOGIN_BG_IMG);

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const initialGetBgImg = async () => {
    const resp = await homeSliderStore.getBgImg();
    if (resp.status === 200) {
      if (resp?.data?.slider?.bg_img ?? "" !== "")
        setBgImg(resp.data.slider.bg_img);
    }
  };
  useEffect(() => {
    initialGetBgImg();
    // Update the currentDateTime every second
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once

  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1
            )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1
        ),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  const handleLogout = () => {
    userInfo.reset();
    auth.logout();
    navigate(`/${i18next.language}/sign-in`);
  };

  function changeLanguage(lng) {
    i18next.changeLanguage(lng, (err) => {
      console.warn("An error occured while changin language");
    });
  }

  return (
    <div className="w-full h-full flex">
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        breakPoint="md"
        backgroundColor="#202123"
        rootStyles={{
          color: themes[theme].sidebar.color,
          border: 0,
        }}
        className="min-h-screen !h-screen lg:!sticky top-0 !z-50"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            position: "relative",
          }}
        >
          <div className="relative w-full">
            <img
              style={{
                margin: "12px 24px",
              }}
              src={process.env.REACT_APP_LOGO_PATH}
              //src='/images/Produk-Kewangan-Main-Logo.png'
              alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
              className={`w-24 h-8 object-contain object-center duration-300 whitespace-nowrap hidden ${collapsed ? "md:hidden" : "md:block"
                }`}
            />
            <span
              className={`absolute top-3 hidden lg:block right-3 text-white/60 cursor-pointer text-2xl ${
                collapsed ? "rotate-180 right-7" : "rotate-0"
              } duration-300`}
              onClick={() => setCollapsed(!collapsed)}
            >
              <HiChevronLeft />
            </span>
          </div>
          <div style={{ marginBottom: "24px", marginTop: "16px" }} />
          <div style={{ flex: 1, marginBottom: "32px" }}>
            {userMenu.map((menu) => (
              <div key={menu.key}>
                <div style={{ padding: "0 24px", marginBottom: "8px" }}>
                  <Paragraph
                    style={{
                      opacity: collapsed ? 0 : 0.7,
                      letterSpacing: "0.5px",
                    }}
                    className="font-thin capitalize whitespace-nowrap duration-300"
                  >
                    {t(`member.menu.${menu.menu}`)}
                  </Paragraph>
                </div>
                <Menu menuItemStyles={menuItemStyles}>
                  {menu.subMenuItems?.length > 0 ? (
                    <SubMenu
                      label={t(`member.menu.${menu.label}`)}
                      icon={<p className="text-xl">{menu?.icon}</p>}
                      className="z-infinity"
                    >
                      {menu.subMenuItems.map((subMenuItem, b) => (
                        <Link
                          to={subMenuItem.path}
                          key={subMenuItem.key}
                          onClick={() => setToggled(!toggled)}
                        >
                          <MenuItem>
                            {t(`member.menu.${subMenuItem.label}`)}
                          </MenuItem>
                        </Link>
                      ))}
                    </SubMenu>
                  ) : null}
                  {menu.menuItems?.length > 0 &&
                    (menu.is2level ?? false) === false &&
                    menu.menuItems.map((a, b) => {
                      return (
                        <Link
                          to={a.path}
                          key={a.key}
                          onClick={() => setToggled(!toggled)}
                        >
                          <MenuItem icon={<p className="text-xl">{a?.icon}</p>}>
                            {t(`member.menu.${a.label}`)}
                          </MenuItem>
                        </Link>
                      );
                    })}
                  {menu.menuItems?.length > 0 &&
                  (menu.is2level ?? false) === true ? (
                    <div className="ml-10">
                      {menu.menuItems.map((menuItem) => {
                        return (
                         <SubMenu
                         label={t(`member.menu.${menuItem.label}`)}
                         //icon={<p className="text-xl">{menuItem?.icon}</p>}
                         className="z-infinity"
                       >
                         {menuItem.subItems.map((subMenuItem, b) => (
                           <Link
                             to={subMenuItem.path}
                             key={subMenuItem.key}
                             onClick={() => setToggled(!toggled)}
                           >
                             <MenuItem>{t(`member.menu.${subMenuItem.label}`)}</MenuItem>
                           </Link>
                         ))}
                       </SubMenu>);
                      })}
                    </div>
                  ) : null}
                </Menu>
              </div>
            ))}
            <div className="w-full px-2 my-5">
              <ButtonLogout
                open={!collapsed}
                action={handleLogout}
                label={t("member.menu.Logout")}
              />
            </div>
          </div>
        </div>
      </Sidebar>
      <main className="h-full  w-full relative overflow-hidden">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url('${bgImg}')`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            opacity: 0.3,
            zIndex: -1,
          }}
        ></div>
        <div id="top-header" className="w-full bg-primary h-16 p-3">
          <div className="w-full h-full flex justify-between items-center">
            <div className="flex items-center space-x-3">
              <button
                className={`block opacity-100 cursor-pointer lg:hidden text-2xl text-white duration-500`}
                onClick={() => setToggled(!toggled)}
              >
                <HiOutlineMenu />
              </button>
              <img
                src={process.env.REACT_APP_LOGO_PATH}
                //src='/images/Produk-Kewangan-Main-Logo.png'
                alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
                className="w-24 h-8 object-contain object-center lg:hidden"
              />
            </div>
            <div className="flex flex-row gap-3 items-center">
              {mockLanguages.length > 1 && (
                <select
                  defaultValue="en"
                  className="border-none text-sm h-1/2 "
                  onChange={(e) => changeLanguage(e.target.value)}
                  value={i18next.language}
                >
                  {mockLanguages.map((langCode) => (
                    <option key={langCode} value={langCode}>
                      {langCode.toUpperCase()}
                    </option>
                  ))}
                </select>
              )}

              <Heading
                size="h4"
                color="white"
                className="text-end font-semibold capitalize"
              >
                <p className="text-white text-sm font-semibold">
                  GMT+8: {convertTime(currentDateTime)}
                </p>
                {userInfo?.user?.status != "missinginfo"
                  ? userInfo?.user?.fullName || null
                  : "N/A"}
              </Heading>
            </div>
          </div>
        </div>
        <div
          style={{ minHeight: "calc(100vh - 8rem )" }}
          className="px-3 py-1 lg:px-7 lg:pt-7 lg:pb-12 mb-0"
        >
          <Suspense fallback={<Loading />}>
            <Outlet />
            {children}
          </Suspense>
        </div>
        <Spacing></Spacing>
        <div className="w-full">
          <div className="flex flex-col-reverse lg:flex-row items-center px-5 py-2 justify-between gap-4 w-full">
            <Link to="/" className="flex items-center mb-4 sm:mb-0">
              <span className="self-center text-sm font-semibold whitespace-nowrap text-gray-400">
                {`© 2023 ${process.env.REACT_APP_TITLE}`}
              </span>
            </Link>
            <ul className="flex items-center mb-6 text-sm font-semibold text-gray-500 sm:mb-0 dark:text-gray-400">
              <li>
                <Link to="/about-us" className="mr-4 hover:text-white md:mr-6">
                  {t("member.footer.AboutUs")}
                </Link>
              </li>
              <li>
                <Link to="/news" className="mr-4 hover:text-white md:mr-6">
                  {t("member.footer.News")}
                </Link>
              </li>
              <li>
                <Link
                  to="/risk-disclosure"
                  className="mr-4 hover:text-white md:mr-6"
                >
                  {t("member.footer.RiskDisclosure")}
                </Link>
              </li>
              <li>
                <Link
                  to="/return-policy"
                  className="mr-4 hover:text-white md:mr-6 "
                >
                  {t("member.footer.ReturnPolicy")}
                </Link>
              </li>
              <li>
                <Link to="/aml-policy" className="mr-4 hover:text-white">
                  {t("member.footer.AMLPolicy")}
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" className="hover:text-white">
                  {t("member.footer.PrivacyPolicy")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
});

export default DashboardLayout;
